import { useEffect, useRef } from 'react';

interface DebounceOptions {
  leading?: boolean;
}

function useDebouncedEffect<T extends any[]>(
  func: (...args: T) => void,
  deps: any,
  delay: number,
  options: DebounceOptions = { leading: true }
) {
  const leading = useRef<boolean>(false);

  useEffect(() => {
    if (options.leading && !leading.current) {
      func(...deps);
      leading.current = true;
      return;
    }

    const handler = setTimeout(() => func(...deps), delay);
    return () => clearTimeout(handler);
  }, [...deps, delay]);
}

export default useDebouncedEffect;
