import React, {
  createContext,
  ReactElement,
  useContext,
  useRef,
  useState,
} from 'react';
import { SingleValue } from 'react-select';
import useDebouncedEffect from '@/hooks/useDebouncedEffect';
import {
  compact,
  find,
  isEmpty,
  isNil,
  isNull,
  map,
  reject,
  toPlainObject,
  uniqBy,
} from 'lodash';
import { OptionType } from '@/utils/types';
import { queryService } from '@wap-client/services';
import { useApp } from '@wap-client/core';
import { AssetProps, PoiProps, RouteProps, StepProps } from './types';
import { GoogleMapContext } from '@/components/base/google-map/Context';

export const PlanYourTripsContext = createContext<{
  route: RouteProps;
  isRouteLoading: boolean;
  selectedOriginOption: OptionType | null;
  setSelectedOriginOption: React.Dispatch<
    React.SetStateAction<OptionType | null>
  >;
  selectedDestinationOption: OptionType | null;
  setSelectedDestinationOption: React.Dispatch<
    React.SetStateAction<OptionType | null>
  >;
}>({
  route: {
    points: [],
    steps: [],
  },
  isRouteLoading: false,
  selectedOriginOption: null,
  setSelectedOriginOption: () => {},
  selectedDestinationOption: null,
  setSelectedDestinationOption: () => {},
});

const INITIAL_ROUTE_STATE = {
  points: [],
  steps: [],
};

let DEFAULT_ORIGIN_OPTION: any = null;
let DEFAULT_DESTINATION_OPTION: any = null;

// @TODO: we'll remove it before the real production time
if (process.env.NODE_ENV === 'development') {
  DEFAULT_ORIGIN_OPTION = {
    label: 'İstanbul',
    value: '337c7fa5-4387-455e-aa16-d7fa9b1e8097',
    rawData: {
      id: '337c7fa5-4387-455e-aa16-d7fa9b1e8097',
      lat: 41.0082376,
      lng: 28.9783589,
      title: 'İstanbul',
    },
  };

  DEFAULT_DESTINATION_OPTION = {
    label: 'İzmir',
    value: '3ae54deb-aecb-4d2f-89a9-4ddf59356eef',
    rawData: {
      id: '3ae54deb-aecb-4d2f-89a9-4ddf59356eef',
      lat: 38.423734,
      lng: 27.142826,
      title: 'İzmir',
    },
  };
}

const PlanYourTripsContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const app = useApp();
  const nearyRouteControllerRef = useRef<AbortController | null>(null);
  const { selectedRoutePoints, resetSelectedPoints } =
    useContext(GoogleMapContext);

  // states
  const [route, setRoute] = useState<RouteProps>(INITIAL_ROUTE_STATE);
  const [isRouteLoading, setRouteLoading] = useState<boolean>(false);
  const [selectedOriginOption, setSelectedOriginOption] = useState<
    SingleValue<OptionType>
  >(DEFAULT_ORIGIN_OPTION);
  const [selectedDestinationOption, setSelectedDestinationOption] = useState<
    SingleValue<OptionType>
  >(DEFAULT_DESTINATION_OPTION);

  // actions
  const fetchPYTAssetDetails = async (pointIds: string[]) => {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('assetIds', pointIds.join('|'));
      searchParams.append('pageSize', '999');

      const response = await queryService.run<any>(app.environment, {
        name: 'plan-your-trip-assets-get-all',
        language: app.language,
        query: searchParams.toString(),
      });

      if (response && !isEmpty(response.data)) {
        const assets = compact(response.data) as AssetProps[];

        const getPointWithTheAsset = (points: PoiProps[]) => {
          return map(points, (point: PoiProps) => {
            const asset = find(assets, { id: point.id }) as AssetProps;
            if (!asset) {
              return point;
            }

            // add extra subtitle param (district, city)
            const { city, district } = asset;
            const subtitle = !isEmpty(city)
              ? `${!isEmpty(district) ? `${district.title}/` : ''}${city.title}`
              : '';

            return {
              ...point,
              ...asset,
              subtitle,
            };
          });
        };

        setRoute((prevRoute) => ({
          ...prevRoute,
          points: getPointWithTheAsset(prevRoute.points),
        }));
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const fetchPYTNearyRoute = async () => {
    // if the origin and the destination options are not selected
    if (isEmpty(selectedOriginOption) && isEmpty(selectedDestinationOption)) {
      setRoute(INITIAL_ROUTE_STATE);
      resetSelectedPoints();
      return;
    }

    try {
      const addedFormattedPois = selectedRoutePoints.map(
        ({ id, lat, lng }) => ({ id, lat, lng })
      );

      const payload: any = {
        pois: addedFormattedPois,
      };

      // append origin coordinates
      if (!isEmpty(selectedOriginOption)) {
        const { id, lat, lng } = toPlainObject(selectedOriginOption.rawData);
        payload.startId = id;
        payload.startLat = lat;
        payload.startLng = lng;
      }

      // append destination coordinates
      if (!isEmpty(selectedDestinationOption)) {
        const { id, lat, lng } = toPlainObject(
          selectedDestinationOption.rawData
        );
        // if the origin option is empty then append to start params
        if (isEmpty(selectedOriginOption)) {
          payload.startId = id;
          payload.startLat = lat;
          payload.startLng = lng;
        } else {
          payload.endId = id;
          payload.endLat = lat;
          payload.endLng = lng;
        }
      }

      // start loading
      setRouteLoading(true);

      // if there is an already request then cancel it
      if (!isNull(nearyRouteControllerRef.current)) {
        nearyRouteControllerRef.current.abort();
        nearyRouteControllerRef.current = null;
      }

      nearyRouteControllerRef.current = new AbortController();
      const signal = nearyRouteControllerRef.current.signal;

      const request = await fetch(
        `https://cms-ui-api.goturkiyetest.com.tr/goturkiye/triproute/en-us/neary-route`,
        {
          signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // JSON gönderileceğini belirt
          },
          body: JSON.stringify(payload),
        }
      );
      const response = await request.json();
      if (response && response.data) {
        const { pois, steps } = response.data || {};
        const points = compact(pois) as PoiProps[];

        // data
        const getFilteredPoints = (pointsData: PoiProps[]) => {
          return uniqBy(
            reject(
              pointsData,
              (point: PoiProps) =>
                isNil(point.id) || isNil(point.lng) || isNil(point.lat)
            ),
            'id'
          );
        };

        const getFilteredSteps = (stepsData: any): StepProps[] => {
          const formattedSteps = map(
            stepsData,
            ({
              start_location: coordinates,
            }: {
              start_location: StepProps;
            }) => {
              if (!coordinates) {
                return null;
              }

              return {
                lat: Number(coordinates.lat),
                lng: Number(coordinates.lng),
              };
            }
          );

          return compact(formattedSteps);
        };

        setRoute((prevRoute) => ({
          points: getFilteredPoints([...prevRoute.points, ...points]),
          steps: getFilteredSteps(steps),
        }));

        // fetch poi (asset) details by poi.id
        const pointIds = map(points, 'id') || [];
        fetchPYTAssetDetails(pointIds);
      } else {
        setRoute(INITIAL_ROUTE_STATE);
      }

      // close loading
      setRouteLoading(false);
    } catch (err) {
      if (err instanceof DOMException && err.name !== 'AbortError') {
        setRouteLoading(false);
        return;
      }
      console.error('Error:', err);
    }
  };

  // effects
  useDebouncedEffect(
    () => {
      fetchPYTNearyRoute();
    },
    [selectedOriginOption, selectedDestinationOption],
    300
  );

  useDebouncedEffect(
    () => {
      fetchPYTNearyRoute();
    },
    [selectedRoutePoints],
    300
  );

  const value = {
    // states
    route,
    isRouteLoading,
    selectedOriginOption,
    setSelectedOriginOption,
    setSelectedDestinationOption,
    selectedDestinationOption,
  };

  return (
    <PlanYourTripsContext.Provider value={value}>
      {children}
    </PlanYourTripsContext.Provider>
  );
};

export default PlanYourTripsContextProvider;
