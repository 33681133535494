import { Container } from '@/components/base/gridview';
import { IPlanYourTripsProps } from '@/components/widgets/plan-your-trip/types';
import PlanYourTripFilters from '@/components/widgets/plan-your-trip/filters/Filters';
import PlanYourTripMap from '@/components/widgets/plan-your-trip/map/Map';
// import PlanYourTripToolbar from '@/components/widgets/plan-your-trip/toolbar/Toolbar';
import PlanYourTripsContextProvider from '@/components/widgets/plan-your-trip/Context';
import GoogleMapContextProvider from '@/components/base/google-map/Context';

const PlanYourTrip: React.FC<IPlanYourTripsProps> = () => {
  return (
    <GoogleMapContextProvider showActions>
      <PlanYourTripsContextProvider>
        <div className="plan-your-trip" id="plan-your-trip">
          <Container size="wide">
            <PlanYourTripFilters />
            <PlanYourTripMap />
            {/* {deviceType !== 'desktop' && <PlanYourTripToolbar />} */}
          </Container>
        </div>
      </PlanYourTripsContextProvider>
    </GoogleMapContextProvider>
  );
};

export default PlanYourTrip;
