import Icon from '@/components/base/icon/Icon';
import useDeviceType from '@/hooks/useDeviceType';
import { functions } from '@wap-client/core';
import { isEmpty } from 'lodash';
import { forwardRef } from 'react';
import { InView } from 'react-intersection-observer';
import Select, {
  ActionMeta,
  OptionProps,
  components as Components,
  ValueContainerProps,
} from 'react-select';

const ClearIndicator = (props: any) => {
  return (
    <Icon
      name="icon-close-2"
      size="small"
      className="react-select__clear-indicator"
      {...props.innerProps}
    />
  );
};

const ValueContainer = (props: ValueContainerProps) => {
  const selectedOption = props.selectProps.value as { label?: string };
  return (
    <Components.ValueContainer
      innerProps={{
        ...props.innerProps,
        title: !isEmpty(selectedOption) ? selectedOption.label : undefined,
      }}
      {...props}
    />
  );
};

const MenuList = (props: any) => {
  const {
    isLoading,
    selectProps: { onMenuScrollToBottom },
    children,
  } = props || {};
  const canLoadMore = !!onMenuScrollToBottom;

  // actions
  const onChangeInView = (inView: boolean) => {
    if (inView) {
      onMenuScrollToBottom && onMenuScrollToBottom();
    }
  };

  return (
    <Components.MenuList {...props}>
      {children}
      {canLoadMore && (
        <InView
          skip={isLoading}
          style={{ position: 'relative', top: '-10px' }}
          onChange={onChangeInView}
        />
      )}
    </Components.MenuList>
  );
};

const normalizeString = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

const filterOption = (
  option: { label: string; value: string },
  inputValue: string
) => {
  const normalizedLabel = normalizeString(option.label);
  const normalizedInputValue = normalizeString(inputValue);
  return normalizedLabel.includes(normalizedInputValue);
};

const PlanYourTripSelectInput = forwardRef<Select, any>(
  (
    {
      className,
      // react select options
      onChange: onChangeCallback,
      options,
      hideSelectedOptions = false,
      isCreatable,
      isMulti,
      isClearable = true,
      noIndicator = false,
      ...rest
    },
    ref
  ) => {
    const deviceType = useDeviceType();

    const onChange = (
      option: OptionProps | null,
      meta: ActionMeta<OptionProps>
    ) => {
      onChangeCallback && onChangeCallback(option, meta);
    };

    return (
      <Select
        ref={ref}
        type="select"
        className={functions.classnames('react-select', className)}
        classNamePrefix="react-select"
        filterOption={filterOption}
        openMenuOnFocus={deviceType === 'desktop'}
        loadingMessage={() => 'Loading...'}
        menuShouldScrollIntoView={true}
        menuPlacement="auto"
        hideSelectedOptions={
          [null, undefined].includes(hideSelectedOptions)
            ? !isMulti
            : hideSelectedOptions
        }
        components={{
          ClearIndicator,
          ValueContainer,
          MenuList,
        }}
        isMulti={isMulti}
        // textEllipsisOption={textEllipsisOption}
        // noOptionsMessage={() =>
        //   noOptionsMessage === false ? null : t('common.no_options')
        // }
        isClearable={isClearable}
        options={options}
        onChange={onChange}
        {...rest}
      />
    );
  }
);

export default PlanYourTripSelectInput;
