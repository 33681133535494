interface IOptions {
  labelProp: string | number;
  valueProp: string | number;
}

export const dataToSelectOptions = (
  data: unknown[],
  initialOptions: IOptions
) => {
  const options = {
    labelProp: initialOptions.labelProp || 'title',
    valueProp: initialOptions.valueProp || 'id',
  };

  if (!Array.isArray(data)) {
    return null;
  }

  return data.map((item: any) => ({
    rawData: item,
    label: item[options.labelProp],
    value: item[options.valueProp],
  }));
};

export const selectOptionsToData = (data: IOptions[]) => {
  if (!Array.isArray(data)) {
    return null;
  }

  return data.map((item: any) => item.rawData);
};
