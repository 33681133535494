import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IPlanYourTripsMapProps } from '@/components/widgets/plan-your-trip/map/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/Context';
// import PlanYourTripMapListWaypoints from '@/components/widgets/plan-your-trip/map/list-waypoints';
import GoogleMapPointList from '@/components/base/google-map/point-list/PointList';
import { motion } from 'framer-motion';
import Spinner from '@/components/base/spinner';
import GoogleMap from '@/components/base/google-map/GoogleMap';
import { first, isEmpty, isNil, last } from 'lodash';
import { MarkerClusterer, PolylineF } from '@react-google-maps/api';
import PointMarker from '@/components/base/google-map/point-marker';
import { PointProps } from '../types';
import GoogleMapTopbar from '@/components/base/google-map/topbar/Topbar';
import { functions } from '@wap-client/core';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { toCoordinatesNumber } from '@/utils/base/map';
import { OptionType } from '@/utils/types';

const animations = {
  map: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        delay: 0.4,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PlanYourTripMap: React.FC<IPlanYourTripsMapProps> = () => {
  const { selectedRoutePoints, getSelectedPointOrder } =
    useContext(GoogleMapContext);
  const {
    route,
    isRouteLoading,
    selectedOriginOption,
    selectedDestinationOption,
  } = useContext(PlanYourTripsContext);

  // states
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null);

  // actions
  const onInfoBoxShow = (point: PointProps) => {
    setSelectedPointId(point.id);
  };

  const onInfoBoxHide = () => {
    setSelectedPointId(null);
  };

  const getOptionCoordinates = useCallback(
    (option: OptionType | undefined | null) => {
      if (isNil(option)) {
        return null;
      }

      return toCoordinatesNumber({
        lat: option.rawData.lat,
        lng: option.rawData.lng,
      });
    },
    []
  );

  const directionsOptions = useMemo(() => {
    return {
      origin: getOptionCoordinates(selectedOriginOption),
      destination: getOptionCoordinates(selectedDestinationOption),
      waypoints: selectedRoutePoints.map((point) => ({
        lat: point.lat,
        lng: point.lng,
      })),
    };
  }, [selectedOriginOption, selectedDestinationOption, selectedRoutePoints]);

  return (
    <motion.div className="google-map" variants={animations.map}>
      <div className="google-map-wrapper">
        {isRouteLoading && (
          <Spinner
            size="small"
            className={functions.classnames({
              'spinner-center': isEmpty(route.points),
            })}
          />
        )}
        <GoogleMapPointList points={route.points} showToolbar />
        <GoogleMapTopbar />
        <GoogleMap
          options={{
            zoom: 6,
          }}
          directions={directionsOptions}
        >
          {/* {!isEmpty(route.steps) && (
            <PolylineF
              path={route.steps}
              options={{
                strokeColor: '#2256d3',
                strokeOpacity: 1,
                strokeWeight: 5,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
              }}
            />
          )} */}
          {!isEmpty(route.points) && (
            <MarkerClusterer
              options={{
                clusterClass: 'plan-your-trip-map-pin-clusterer',
                enableRetinaIcons: false,
                minimumClusterSize: 2,
                averageCenter: true,
              }}
            >
              {(clusterer) => (
                <>
                  {route.points.map((point: PointProps) => (
                    <PointMarker
                      key={point.id}
                      order={getSelectedPointOrder(point)}
                      point={point}
                      clusterer={clusterer}
                      showInfoBox={selectedPointId === point.id}
                      onInfoBoxShow={onInfoBoxShow}
                      onInfoBoxHide={onInfoBoxHide}
                    />
                  ))}
                </>
              )}
            </MarkerClusterer>
          )}
        </GoogleMap>
      </div>
    </motion.div>
  );
};

export default PlanYourTripMap;
